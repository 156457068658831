import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { nextPage, previousPage } from "../store/actions/questionsActions";

const usePageNavigation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pageNumber = useSelector(state => state.pageNumber);
  const [pageLimits] = useState({
    start: 0,
    end: 10
  });

  const next = () => {
    window.scrollTo(0, 0);
    if (pageNumber < pageLimits.end) {
      dispatch(nextPage());
    }
  };

  const previous = () => {
    window.scrollTo(0, 0);
    if (pageNumber > pageLimits.start) {
      dispatch(previousPage());
    }
  };

  useEffect(() => {
    switch (pageNumber) {
      case 1:
        history.push("/");
        break;
      case 2:
      case 6:
        history.push("/questions");
        break;
      case 7:
        history.push("/result");
        break;
      case 8:
        history.push("/form");
        break;
      case 9:
        history.push("/final");
        break;
      default:
        return;
    }
  }, [history, pageNumber]);

  return {
    next,
    previous
  };
};

export default usePageNavigation;
