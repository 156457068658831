import React from "react";
import style from "./Input.module.css";

const Input = ({
  type,
  placeholder,
  value,
  onChangeHandler,
  label,
  disabled,
  isOptional,
  errors
}) => {
  return (
    <div className={style.inputWrapper}>
      <label className={style.label}>
        {label}{" "}
        {isOptional ? (
          <span className={style.optionalIndicator}>( Valfri )</span>
        ) : null}
      </label>
      <input
        disabled={disabled}
        name={label}
        className={style.input}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={e => onChangeHandler(e.target.value)}
      />
      <ul className={style.errorContainer}>
        {errors.map((error, idx) => (
          <li className={style.error} key={idx}>
            {error}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Input;
