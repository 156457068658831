import React from "react";
import "./App.css";
import Header from "./components/layout/Header/Header";
import Content from "./components/layout/Content/Content";
import Footer from "./components/layout/Footer/Footer";
import QuestionsPage from "./components/pages/QuestionsPage/QuestionsPage";
import StartPage from "./components/pages/StartPage/StartPage";
import Result from "./components/pages/ResultsPage/ResultsPage";
import ContactUsPage from "./components/pages/ContactUsPage/ContactUsPage";
import FinalPage from "./components/pages/FinalPage/FinalPage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="app">
      <Header />
      <Content>
        <Router>
          <Switch>
            <Route path="/final" component={FinalPage} />
            <Route path="/form">
              <ContactUsPage />
            </Route>
            <Route path="/questions">
              <QuestionsPage />
            </Route>
            <Route path="/result">
              <Result />
            </Route>
            <Route path="/">
              <StartPage />
            </Route>
          </Switch>
        </Router>
      </Content>
      <Footer />
      <Helmet>
        <script>
          {`
            var mtr_custom = mtr_custom || {};
            var mtr_site_id = 969116;
            (function () {
                 var s = document.createElement('script'); s.async = true; s.src = '//t.myvisitors.se/js?site_id=' + mtr_site_id;
                 (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(s);
                 var sc = document.createElement('script'); sc.async = true; sc.src = '//t.myvisitors.se/js/' + mtr_site_id + (window.location.href.indexOf('tb-nocache') > -1 ? '?v=' + Math.floor(Math.random()*999) : '');
                 (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(sc);
            })();
          `}
        </script>
      </Helmet>
    </div>
  );
}

export default App;
