const firebase = require("firebase");
require("firebase/firestore");

const config = {
  apiKey: "AIzaSyDckfUa9w7Olu3jpV_vA3IsrKKNGfXdjEc",
  authDomain: "qa-assesment.firebaseapp.com",
  databaseURL: "https://qa-assesment.firebaseio.com",
  projectId: "qa-assesment",
  storageBucket: "qa-assesment.appspot.com",
  messagingSenderId: "501077766997",
  appId: "1:501077766997:web:e36016944576062b9a75dc",
  measurementId: "G-8JV63CXDJZ"
};

firebase.initializeApp(config);
export const db = firebase.firestore();
