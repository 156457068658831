import React, { useState, useEffect } from "react";
import style from "./ResultsPage.module.css";
import NavigationMenu from "../../molecules/NavigationMenu/NavigationMenu";
import ForwardButton from "../../molecules/ForwardButton/ForwardButton";
import BackButton from "../../molecules/BackButton/BackButton";
import { useSelector } from "react-redux";
import usePageNavigation from "../../../hooks/usePageNavigation";
import contentful from "../../../config/contentful";
import LoadingScreen from "../../molecules/LoadingScreen/LoadingScreen";

function Result() {
  const navigation = usePageNavigation();
  const resultValue = useSelector((state) => state.result);
  const [answer, setAnswer] = useState([]);
  const [contentStructure, setContentStructure] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    let url = "32nnP5GR2yXKZIyHqTpyQv";
    getContent(url);
  }, []);

  const getContent = (url) => {
    setIsLoading(true);
    contentful.getEntry(url).then(({ fields }) => {
      if (Object.keys(fields).length === 0) return;
      setContentStructure(fields);
    });
    contentful.getEntries("answer").then(({ items = [] }) => {
      setIsLoading(false);
      const newAnswer = items.reduce((list, item) => {
        if (item.sys.contentType.sys.id === "answer")
          list.push({
            id: item.fields.id,
            title: item.fields.title,
            description:
              item.fields.description.content[0].content[0].value || null,
          });
        return list;
      }, []);
      console.log(newAnswer);

      newAnswer.sort((a, b) => (a.id > b.id ? 1 : -1));

      setAnswer(newAnswer);
    });
  };

  function calculateSum(sum) {
    if (sum < 0.01) {
      return { title: answer[0].title, number: answer[0].id };
    } else if (sum < 0.56) {
      return { title: answer[1].title, number: answer[1].id };
    } else if (sum <= 0.59) {
      return { title: answer[2].title, number: answer[2].id };
    } else if (sum < 0.8) {
      return { title: answer[3].title, number: answer[3].id };
    } else if (sum <= 0.85) {
      return { title: answer[4].title, number: answer[4].id };
    } else if (sum <= 1) {
      return { title: answer[5].title, number: answer[5].id };
    } else {
      return "Undefined";
    }
  }
  function calculateTot() {
    var obj = resultValue;
    var sum = 0;
    for (var el in obj) {
      if (obj.hasOwnProperty(el)) {
        sum += parseFloat(obj[el].sum);
      }
    }

    sum = sum / 5;
    if (sum < 0.01) {
      return {
        title: answer[0].title,
        number: answer[0].id,
        description: answer[0].description,
      };
    } else if (sum < 0.56) {
      return {
        title: answer[1].title,
        number: answer[1].id,
        description: answer[1].description,
      };
    } else if (sum <= 0.59) {
      return {
        title: answer[2].title,
        number: answer[2].id,
        description: answer[2].description,
      };
    } else if (sum < 0.8) {
      return {
        title: answer[3].title,
        number: answer[3].id,
        description: answer[3].description,
      };
    } else if (sum <= 0.85) {
      return {
        title: answer[4].title,
        number: answer[4].id,
        description: answer[4].description,
      };
    } else if (sum <= 1) {
      return {
        title: answer[5].title,
        number: answer[5].id,
        description: answer[5].description,
      };
    } else {
      return "Undefined";
    }
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className={style.result}>
      <div className={style.textArea}>
        <div className={style.title}>{contentStructure.title}</div>
        <div className={style.description}>
          <div className={style.primaryResult}>
            <div className={style.number}>
              {answer.length > 0 && calculateTot().number}
            </div>
          </div>
          <div className={style.primaryDescription}>
            <div className={style.subTitle}>
              {answer.length > 0 && calculateTot().title}
            </div>
            <div className={style.primaryResultDescription}>
              {answer.length > 0 && calculateTot().description}
            </div>
          </div>
        </div>
        <hr />
        {resultValue.map((question) => {
          return (
            <div className={style.subResult} key={question.title}>
              <div className={style.subValue}>
                <div className={style.number}>
                  {answer.length > 0 && calculateSum(question.sum).number}
                </div>
              </div>
              <div className={style.subDescription}>
                <div className={style.subTitle}>{question.title}</div>
                <div className={style.subText}>
                  {answer.length > 0 && calculateSum(question.sum).title}
                </div>
              </div>
            </div>
          );
        })}
        <hr />
        <NavigationMenu>
          <BackButton onClick={navigation.previous} />
          <ForwardButton onClick={navigation.next} />
        </NavigationMenu>
      </div>
    </div>
  );
}

export default Result;
