import React from "react";
import styles from "./GdprDisclaimer.module.css";
import Row from "../../../Atoms/Row/Row";
import Column from "../../../Atoms/Column/Column"

const GdprDisclaimer = ({ value, onChangeHandler }) => {
  return (
    <Row>
      <Column>
        <div className={styles.disclaimer}>
          <div>
            <input
              type="checkbox"
              checked={value}
              onChange={e => onChangeHandler(e.target.checked)}
            />
          </div>
          <div className={styles.disclaimerText}>
            <div className={styles.emphasizedText}>
              Jag har läst och godkänner "Så här behandlar vi dina
              personuppgifter"
            </div>
            <a
              className={styles.disclaimerLink}
              href="https://www.zingtongroup.com/information_om_personuppgiftsbehandling/"
            >
              Så här behandlar vi dina personuppgifter
            </a>
          </div>
        </div>
      </Column>
    </Row>
  );
};

export default GdprDisclaimer;
