import { db } from "../config/firebase";
import { useState } from "react";
import config from "../config/triggerMail";

const useFirestore = () => {
  const [isSaving, setIsSaving] = useState(false);

  const _parseQuestions = (results) => {
    let parsedString = ``;
    results.answers.forEach((section, idx) => {
      let parsedQuestions = ``;
      let questionIndex = 1;
      for (let prop in section) {
        if (prop !== "title" && prop !== "sum") {
          parsedQuestions += `
            <div>
              <div>Fråga #${questionIndex}: ${results.answers[idx][prop].questionText} </div>
              <div>Svar: ${results.answers[idx][prop].value} </div>
            </div><br>
          `;
        }
        questionIndex++;
      }
      parsedString += `
      <div>
        <h3> ${results.answers[idx].title} </h3>
        <div>${parsedQuestions}</div> 
      </div>`;
    });
    return parsedString;
  };

  const _sendConfirmationMail = (results) => {
    db.collection("mail")
      .add({
        to: config.recipientMailAdress,
        message: {
          subject: `${results.client.firstName} ${results.client.lastName} har fyllt i formuläret "qa-assessment"!`,
          html: `
          <div>
            <h2>Kontaktinfo</h2>
            <div>Besvarades av: ${results.client.firstName} ${
            results.client.lastName
          }</div>
            <div>Email: ${results.client.email}</div>
            <div>Företag: ${results.company.companyName}</div>
            <div>Antal anställda: ${results.company.employeeCount}</div>
          </div>
          <br><br>
          <div>
            <h2>Resultat:</h2>
            ${_parseQuestions(results)}
          </div>
        `,
        },
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveQuestionaireResults = async (results) => {
    return new Promise((resolve, reject) => {
      setIsSaving(true);
      console.log(results);
      db.collection("results")
        .add(results)
        .then((res) => {
          setIsSaving(false);
          _sendConfirmationMail(results);
          resolve("Succefully added result to the database!");
        })
        .catch((err) => {
          setIsSaving(false);
          console.log(err);
          reject("Something went wrong with adding result to database!");
        });
    });
  };

  return {
    isSaving,
    saveQuestionaireResults,
  };
};

export default useFirestore;
