import React from "react";
import Button from "../../Atoms/Button/Button"
import leftArrow from "../../../assets/icons/Left-Arrow.svg";
import styles from "./BackButton.module.css";

const BackButton = ({ text = "Gå tillbaka", onClick, disabled }) => {
  return (
    <Button isFrame={true} onClick={onClick}>
      <img src={leftArrow} alt="left-arrow" />
      <span className={styles.buttonText} style={{ margin: "0 1rem" }}>
        {text}
      </span>
    </Button>
  );
};

export default BackButton;
