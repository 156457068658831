import React, { useState, useEffect } from "react";
import styles from "./FinalPage.module.css";
import Row from "../../Atoms/Row/Row";
import Column from "../../../components/Atoms/Column/Column"
import ForwardButton from "../../molecules/ForwardButton/ForwardButton";
import contentful from "../../../config/contentful";
import LoadingScreen from "../../molecules/LoadingScreen/LoadingScreen";

const FinalPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState();

  useEffect(() => {
    const url = "3mYUxi63CwnbtBqeJoWfCj";
    contentful.getEntry(url).then(entry => {
      setContent(entry.fields);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className={styles.final}>
      <Row>
        <Column>
          <h1 className={styles.titleMsg}>{content.goodByeMessage}</h1>
        </Column>
      </Row>
      <Row>
        <Column>
          <div className={styles.actionsRow}>
            <ForwardButton
              text="Gå till startsidan"
              onClick={() =>
                (window.location.href = "https://www.zingtongroup.com/")
              }
            />
          </div>
        </Column>
      </Row>
    </div>
  );
};

export default FinalPage;
