import React from "react";
import styles from "./ContactPageHeader.module.css";
import Row from "../../../Atoms/Row/Row";
import Column from "../../../Atoms/Column/Column";

const ContactPageHeader = ({ title, description }) => {
  return (
    <Row>
      <Column>
        <h1 className={styles}>{title}</h1>
        <p className={styles.titleDescription}>{description}</p>
      </Column>
    </Row>
  );
};

export default ContactPageHeader;
