import React, { useState, useEffect } from "react";
import style from "./QuestionsPage.module.css";
import NavigationMenu from "../../molecules/NavigationMenu/NavigationMenu";
import ForwardButton from "../../molecules/ForwardButton/ForwardButton";
import BackButton from "../../molecules/BackButton/BackButton";
import { useDispatch, useSelector } from "react-redux";
import usePageNavigation from "../../../hooks/usePageNavigation";
import {
  addTitle,
  addQuestionDescriptions
} from "../../../store/actions/questionsActions";
import Answers from "./Answers/Answers";
import contentful from "../../../config/contentful";
import LoadingScreen from "../../molecules/LoadingScreen/LoadingScreen";
import Row from "../../Atoms/Row/Row";
import Column from "../../Atoms/Column/Column";

function Questions() {
  const navigation = usePageNavigation();
  const pageNumber = useSelector(state => state.pageNumber);
  const resultValue = useSelector(state => state.result);
  const dispatch = useDispatch();
  const [questionSlide, setQuestionSlide] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let url;
    if (pageNumber === 2) {
      url = "4vG5BiRyByjur80aOW4QEZ";
    } else if (pageNumber === 3) {
      url = "4LNbCSDfgt0ghKlwaqh1eK";
    } else if (pageNumber === 4) {
      url = "4HahxD9C8svpuNRJvJOGv9";
    } else if (pageNumber === 5) {
      url = "1KYLahjVE7mhkaUJJA329a";
    } else if (pageNumber === 6) {
      url = "63U2N2IcSLDgoTG9I2HLlD";
    }
    if (url) {
      getContent(url);
    }
  }, [pageNumber]);

  const getContent = url => {
    setIsLoading(true);
    contentful.getEntry(url).then(function(entry) {
      setIsLoading(false);
      setQuestionSlide({
        id: entry.fields.id,
        title: entry.fields.questionTitle,
        description:
          entry.fields.questionDescription.content[0].content[0].value,
        firstQuestion: entry.fields.firstQuestion.content[0].content[0].value,
        secondQuestion: entry.fields.secondQuestion.content[0].content[0].value,
        thirdQuestion: entry.fields.thirdQuestion.content[0].content[0].value,
        fourthQuestion: entry.fields.fourthQuestion.content[0].content[0].value,
        fifthQuestion: entry.fields.fifthQuestion.content[0].content[0].value
      });
    });
  };

  useEffect(() => {
    dispatch(addQuestionDescriptions(questionSlide.id - 1, questionSlide));
  }, [questionSlide]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className={style.question}>
      <div className={style.textArea}>
        <Row>
          <Column>
            <div className={style.title}>
              {questionSlide.title ? questionSlide.title : ""}
            </div>
            <div className={style.titleDescription}>
              {questionSlide.description ? questionSlide.description : ""}
            </div>
            <div className={style.questionNumber}>Fråga 1</div>
            <div className={style.questionText}>
              {questionSlide.firstQuestion ? questionSlide.firstQuestion : ""}
            </div>
            <Answers
              id={questionSlide.id - 1}
              questionText={questionSlide.firstQuestion}
              questionNumber={"first"}
            />
            <hr />
            <div className={style.questionNumber}>Fråga 2</div>
            <div className={style.questionText}>
              {questionSlide.secondQuestion ? questionSlide.secondQuestion : ""}
            </div>
            <Answers
              id={questionSlide.id - 1}
              questionText={questionSlide.secondQuestion}
              questionNumber={"second"}
            />
            <hr />
            <div className={style.questionNumber}>Fråga 3</div>
            <div className={style.questionText}>
              {questionSlide.thirdQuestion ? questionSlide.thirdQuestion : ""}
            </div>
            <Answers
              id={questionSlide.id - 1}
              questionText={questionSlide.thirdQuestion}
              questionNumber={"third"}
            />
            <hr />
            <div className={style.questionNumber}>Fråga 4</div>
            <div className={style.questionText}>
              {questionSlide.fourthQuestion ? questionSlide.fourthQuestion : ""}
            </div>
            <Answers
              id={questionSlide.id - 1}
              questionText={questionSlide.fourthQuestion}
              questionNumber={"fourth"}
            />
            <hr />
            <div className={style.questionNumber}>Fråga 5</div>
            <div className={style.questionText}>
              {questionSlide.fifthQuestion ? questionSlide.fifthQuestion : ""}
            </div>
            <Answers
              id={questionSlide.id - 1}
              questionText={questionSlide.fifthQuestion}
              questionNumber={"fifth"}
            />
          </Column>
        </Row>

        <NavigationMenu>
          <BackButton
            onClick={() => {
              navigation.previous();
            }}
          />
          <ForwardButton
            onClick={() => {
              navigation.next();
              dispatch(addTitle(questionSlide.id - 1, questionSlide.title));
            }}
          />
        </NavigationMenu>
      </div>
    </div>
  );
}

export default Questions;
