import React, { useState } from "react";
import style from "./ContactForm.module.css";
import Row from "../../../Atoms/Row/Row";
import Column from "../../../Atoms/Column/Column"
import Input from "../../../Atoms/Input/Input";
import GdprDisclaimer from "../GdprDisclaimer/GdprDisclaimer";

const ContactForm = ({ formFields, onChange, isSubmitting }) => {
  return (
    <form className={style.contactForm}>
      <Row>
        <Column>
          <Input
            disabled={isSubmitting}
            name="firstName"
            label="Förnamn"
            value={formFields.firstName.value}
            placeholder="Ditt förnamn"
            errors={formFields.firstName.errors}
            onChangeHandler={value => onChange("firstName", value)}
          />
        </Column>
        <Column>
          <Input
            disabled={isSubmitting}
            name="lastName"
            label="Efternamn"
            value={formFields.lastName.value}
            placeholder="Ditt efternamn"
            errors={formFields.lastName.errors}
            onChangeHandler={value => onChange("lastName", value)}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Input
            isOptional
            disabled={isSubmitting}
            name="companyName"
            label="Företag"
            value={formFields.companyName.value}
            placeholder="Företaget jag representerar"
            errors={formFields.companyName.errors}
            onChangeHandler={value => onChange("companyName", value)}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Input
            isOptional
            disabled={isSubmitting}
            name="employeeCount"
            type="number"
            label="Hur många anställda?"
            value={formFields.employeeCount.value}
            placeholder="Hur många anställda har företaget?"
            errors={formFields.employeeCount.errors}
            onChangeHandler={value => onChange("employeeCount", value)}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Input
            disabled={isSubmitting}
            name="email"
            label="E-postadress"
            value={formFields.email.value}
            placeholder="Din e-post"
            errors={formFields.email.errors}
            onChangeHandler={value => onChange("email", value)}
          />
        </Column>
      </Row>
      <GdprDisclaimer
        disabled={isSubmitting}
        onChangeHandler={value => onChange("gdprCheck", value)}
      />
    </form>
  );
};

export default ContactForm;
