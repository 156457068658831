import React, { useState, useEffect } from "react";
import style from "./ContactUsPage.module.css";
import NavigationMenu from "../../molecules/NavigationMenu/NavigationMenu";
import ForwardButton from "../../molecules/ForwardButton/ForwardButton";
import BackButton from "../../molecules/BackButton/BackButton";
import usePageNavigation from "../../../hooks/usePageNavigation";
import ContactForm from "./ContactForm/ContactForm";
import ContactPageHeader from "./ContactPageHeader/ContactPageHeader";
import useFirestore from "../../../hooks/useFirestore";
import { useSelector } from "react-redux";
import contentful from "../../../config/contentful";
import LoadingScreen from "../../molecules/LoadingScreen/LoadingScreen";
import { Helmet } from "react-helmet";

function Result() {
  const firestore = useFirestore();
  const navigation = usePageNavigation();
  const [content, setContent] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isFormValid, setIsFormValid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const resultValue = useSelector((state) => state.result);

  const [formFields, setFormFields] = useState({
    firstName: {
      isRequired: true,
      isValid: false,
      type: "string",
      value: "",
      errors: [],
    },
    lastName: {
      isRequired: true,
      isValid: false,
      type: "string",
      value: "",
      errors: [],
    },
    companyName: {
      isRequired: false,
      isValid: false,
      type: "string",
      value: "",
      errors: [],
    },
    employeeCount: {
      isRequired: false,
      isValid: false,
      type: "number",
      value: "",
      errors: [],
    },
    email: {
      isRequired: true,
      isValid: false,
      type: "email",
      value: "",
      errors: [],
    },
    gdprCheck: {
      isRequired: true,
      isValid: undefined,
      type: "boolean",
      value: false,
      errors: [],
    },
  });

  const validateField = (field) => {
    field.errors = [];

    if (field.type === "string") {
      if (field.value.length === 0 && field.isRequired) {
        field.errors.push("Fältet är obligatoriskt!");
      }
    }

    if (field.type === "email") {
      if (field.value.length === 0 && field.isRequired) {
        field.errors.push("Fältet är obligatoriskt!");
      }

      if (!field.value.includes("@")) {
        field.errors.push("Fältet måste vara giltig e-postadress.");
      }
    }

    if (field.type === "boolean") {
      if (field.value !== true && field.isRequired) {
        field.errors.push("Fältet är obligatoriskt!");
      }
    }

    if (field.errors.length > 0) {
      field.isValid = false;
    } else {
      field.isValid = true;
    }

    return field;
  };

  const updateFormValues = (key, value) => {
    const newValues = { ...formFields };
    newValues[key].value = value;
    newValues[key] = validateField(newValues[key]);
    setFormFields(newValues);
  };

  const validateForm = () => {
    setIsFormValid(
      ["firstName", "lastName", "email", "gdprCheck"].every((field) => {
        return formFields[field].isValid === true;
      })
    );
  };

  const submitForm = () => {
    setIsSubmitting(true);
    firestore
      .saveQuestionaireResults({
        gdprCheck: formFields.gdprCheck.value,
        client: {
          firstName: formFields.firstName.value,
          lastName: formFields.lastName.value,
          email: formFields.email.value,
        },
        company: {
          companyName: formFields.companyName.value,
          employeeCount: formFields.employeeCount.value,
        },
        answers: resultValue,
      })
      .then((msg) => {
        setIsSubmitting(false);
        navigation.next();
      });
  };

  useEffect(() => {
    validateForm();
  }, [formFields]);

  useEffect(() => {
    const url = "3vguKBZsK4Jk1VWIewE8Ed";
    contentful.getEntry(url).then((entry) => {
      setContent(entry.fields);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className={style.result}>
      <div className={style.textArea}>
        <ContactPageHeader
          title={content.pageTitle}
          description={content.pageDescription}
        />
        <ContactForm
          isSubmitting={isSubmitting}
          formFields={formFields}
          onChange={(key, value) => updateFormValues(key, value)}
        />
        <NavigationMenu>
          <BackButton onClick={navigation.previous} />
          <ForwardButton
            isSubmitting={isSubmitting}
            disabled={!isFormValid}
            text="Skicka in"
            onClick={() => submitForm()}
          />
        </NavigationMenu>
      </div>

      <Helmet>
        <script>
          {`
            if(document.querySelector(".Button_button__2Q1TW") !== null){
              const submitBtn = document.querySelector(".Button_button__2Q1TW");

              submitBtn.addEventListener("click", function () {
                var tbContact = window.mtr_custom || {};
                tbContact.session = tbContact.session || {};
                tbContact.session.name =
                  document.getElementsByName("Förnamn")[0].value +
                  " " +
                  document.getElementsByName("Efternamn")[0].value;
                tbContact.session.organization = document.getElementsByName(
                  "Företag"
                )[0].value;
                tbContact.session.email = document.getElementsByName(
                  "E-postadress"
                )[0].value;

                window.mtr_custom = tbContact;
                mtr.goal("Avslutat QA formuläret");
                });
            }
          `}
        </script>
      </Helmet>
    </div>
  );
}

export default Result;
