import React from "react";
import styles from "./LoadingScreen.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const LoadingScreen = ({ text = "Loading..." }) => {
  return (
    <div className={styles.loadingScreen}>
      <div className={styles.loadingIndicator}>
        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        <p>{text}</p>
      </div>
    </div>
  );
};

export default LoadingScreen;
