import React from "react";
import styles from "./Button.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Button = ({ children, disabled, isSubmitting, isFrame, onClick }) => (
  <button
    className={isFrame ? styles.frameButton : styles.button}
    disabled={disabled || isSubmitting}
    onClick={onClick}
  >
    {isSubmitting ? (
      <FontAwesomeIcon icon={faSpinner} spin size="2x" />
    ) : (
      children
    )}
  </button>
);
export default Button;
