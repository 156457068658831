import React from "react";
import Button from "../../Atoms/Button/Button"
import rightArrow from "../../../assets/icons/Arrow.svg";
import styles from "./ForwardButton.module.css";

const ForwardButton = ({
  text = "Gå vidare",
  onClick,
  disabled,
  isSubmitting
}) => {
  return (
    <Button onClick={onClick} disabled={disabled} isSubmitting={isSubmitting}>
      <span className={styles.buttonText}>{text}</span>
      <img src={rightArrow} alt="right-arrow" />
    </Button>
  );
};

export default ForwardButton;
