import {
  NEXT_PAGE,
  PREVIOUS_PAGE,
  ANSWER_QUESTION,
  ADD_TITLE,
  ADD_QUESTION_DESCRIPTIONS
} from "../actionTypes";

export function addQuestionDescriptions(id, descriptions) {
  return {
    type: ADD_QUESTION_DESCRIPTIONS,
    id: id,
    descriptions: descriptions
  };
}

export function nextPage() {
  return {
    type: NEXT_PAGE
  };
}

export function previousPage() {
  return {
    type: PREVIOUS_PAGE
  };
}

export function answerQuestion(id, questionNumber, value, questionText) {
  return {
    type: ANSWER_QUESTION,
    id: id,
    questionNumber: questionNumber,
    value: value,
    questionText: questionText
  };
}

export function addTitle(id, title) {
  return {
    type: ADD_TITLE,
    id: id,
    title: title
  };
}
