import React from "react";
import style from "./Header.module.css";
import Zington from "./Zington.png";
import { useSelector } from "react-redux";

function Header() {
  const pageNumber = useSelector(state => state.pageNumber);
  return (
    <div className={style.header}>
      <div className={style.headerTitle}>
        <a
          href="https://www.zingtongroup.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Zington} alt="Zington" className={style.headerLogo} />
        </a>
      </div>
      <div className={style.headerProgress}>{pageNumber}/9</div>
    </div>
  );
}

export default Header;
