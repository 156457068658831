import React from "react";
import style from "../QuestionsPage.module.css";
import { useDispatch, useSelector } from "react-redux";
import { answerQuestion } from "../../../../store/actions/questionsActions";

function Answers(props) {
  const dispatch = useDispatch();
  const answer = useSelector(state => {
    if (props.id >= 0) {
      return state.result[props.id][props.questionNumber].value;
    }
    return null;
  });
  return (
    <div className={style.questionAnswers}>
      <div className={style.questionAnswer}>
        <button
          onClick={() => {
            dispatch(
              answerQuestion(
                props.id,
                props.questionNumber,
                0,
                props.questionText
              )
            );
          }}
          className={answer === 0 ? style.selectedButton : style.questionButton}
        >
          0
        </button>
        Aldrig
      </div>
      <div className={style.questionAnswer}>
        <button
          onClick={() =>
            dispatch(
              answerQuestion(
                props.id,
                props.questionNumber,
                1,
                props.questionText
              )
            )
          }
          className={answer === 1 ? style.selectedButton : style.questionButton}
        >
          1
        </button>
        Sällan
      </div>
      <div className={style.questionAnswer}>
        <button
          onClick={() =>
            dispatch(
              answerQuestion(
                props.id,
                props.questionNumber,
                2,
                props.questionText
              )
            )
          }
          className={answer === 2 ? style.selectedButton : style.questionButton}
        >
          2
        </button>
        Ibland
      </div>
      <div className={style.questionAnswer}>
        <button
          onClick={() =>
            dispatch(
              answerQuestion(
                props.id,
                props.questionNumber,
                3,
                props.questionText
              )
            )
          }
          className={answer === 3 ? style.selectedButton : style.questionButton}
        >
          3
        </button>
        Ofta
      </div>
      <div className={style.questionAnswer}>
        <button
          onClick={() =>
            dispatch(
              answerQuestion(
                props.id,
                props.questionNumber,
                4,
                props.questionText
              )
            )
          }
          className={answer === 4 ? style.selectedButton : style.questionButton}
        >
          4
        </button>
        Väldigt ofta
      </div>
      <div className={style.questionAnswer}>
        <button
          onClick={() =>
            dispatch(
              answerQuestion(
                props.id,
                props.questionNumber,
                5,
                props.questionText
              )
            )
          }
          className={answer === 5 ? style.selectedButton : style.questionButton}
        >
          5
        </button>
        Alltid
      </div>
    </div>
  );
}
export default Answers;
