import {
  NEXT_PAGE,
  PREVIOUS_PAGE,
  ANSWER_QUESTION,
  ADD_TITLE,
  ADD_QUESTION_DESCRIPTIONS
} from "../actionTypes";

const initialState = {
  pageNumber: 1,
  result: [
    {
      first: { value: "0" },
      second: { value: "0" },
      third: { value: "0" },
      fourth: { value: "0" },
      fifth: { value: "0" },
      sum: "0",
      title: ""
    },
    {
      first: { value: "0" },
      second: { value: "0" },
      third: { value: "0" },
      fourth: { value: "0" },
      fifth: { value: "0" },
      sum: "0",
      title: ""
    },
    {
      first: { value: "0" },
      second: { value: "0" },
      third: { value: "0" },
      fourth: { value: "0" },
      fifth: { value: "0" },
      sum: "0",
      title: ""
    },
    {
      first: { value: "0" },
      second: { value: "0" },
      third: { value: "0" },
      fourth: { value: "0" },
      fifth: { value: "0" },
      sum: "0",
      title: ""
    },
    {
      first: { value: "0" },
      second: { value: "0" },
      third: { value: "0" },
      fourth: { value: "0" },
      fifth: { value: "0" },
      sum: "0",
      title: ""
    }
  ]
};

function questions(state = initialState, action) {
  switch (action.type) {
    case ADD_QUESTION_DESCRIPTIONS: {
      return {
        ...state,
        result: state.result.map((section, i) => {
          if (i === action.id) {
            return {
              ...section,
              first: {
                ...section.first,
                questionText: action.descriptions.firstQuestion
              },
              second: {
                ...section.second,
                questionText: action.descriptions.secondQuestion
              },
              third: {
                ...section.third,
                questionText: action.descriptions.thirdQuestion
              },
              fourth: {
                ...section.fourth,
                questionText: action.descriptions.fourthQuestion
              },
              fifth: {
                ...section.fifth,
                questionText: action.descriptions.fifthQuestion
              }
            };
          }
          return section;
        })
      };
    }
    case ADD_TITLE: {
      return {
        ...state,
        result: state.result.map((question, i) => {
          if (i === action.id) {
            return {
              ...question,
              title: action.title
            };
          }
          return question;
        })
      };
    }
    case NEXT_PAGE: {
      return {
        ...state,
        pageNumber: state.pageNumber + 1
      };
    }
    case PREVIOUS_PAGE: {
      return {
        ...state,
        pageNumber: state.pageNumber - 1
      };
    }
    case ANSWER_QUESTION: {
      var obj = state.result[action.id];
      var sum = 0;
      for (var el in obj) {
        if (
          obj.hasOwnProperty(el) &&
          el !== "sum" &&
          el !== "title" &&
          el !== "questionText"
        ) {
          if (el === action.questionNumber) {
            sum += action.value;
          } else {
            sum += parseFloat(obj[el].value);
          }
        }
      }

      sum = sum / 25;
      return {
        ...state,
        result: state.result.map((question, i) => {
          if (i === action.id) {
            return {
              ...question,
              [action.questionNumber]: {
                value: action.value,
                questionText: action.questionText
              },
              sum: sum
            };
          }
          return question;
        })
      };
    }
    default:
      return state;
  }
}
export default questions;
