import React from "react";
import styles from "./Footer.module.css";
import Facebook from "./images/Facebook.svg";
import Linkedin from "./images/Linkedin.svg";
import Instagram from "./images/Instagram.svg";

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.footerTop}>
        <a
          href="https://www.zingtongroup.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>www.zingtongroup.com</p>
        </a>
        <p>Birger Jarlsgatan 9</p>
        <p>+46 8-406 68 00</p>
        <p>info@zingtongroup.com</p>
      </div>
      <div className={styles.footerBottom}>
        <div className={styles.footerIcons}>
          <a
            href="https://www.linkedin.com/company/zington/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Linkedin} alt="Linkedin" className={styles.footerIcon} />
          </a>
          <a
            href="https://www.facebook.com/ZingtonGroup/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={Facebook} alt="Facebook" className={styles.footerIcon} />
          </a>
          <a
            href="https://www.instagram.com/zington.group/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Instagram}
              alt="Instagram"
              className={styles.footerIcon}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
