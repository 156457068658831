import React, { useState, useEffect } from "react";
import style from "./StartPage.module.css";
import arrow from "../../../assets/icons/Arrow.svg";
import usePageNavigation from "../../../hooks/usePageNavigation";
import contentful from "../../../config/contentful";
import LoadingScreen from "../../molecules/LoadingScreen/LoadingScreen";

function Header() {
  const navigation = usePageNavigation();
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState();

  useEffect(() => {
    const url = "i9kNsmjodf0XWGSliGUuL";
    contentful.getEntry(url).then(entry => {
      setContent(entry.fields);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  } else {
    return (
      <div className={style.start}>
        <div className={style.textArea}>
          <div className={style.title}>{content.pageTitle}</div>
          <div className={style.subTitle}>{content.pageDescriptionTitle}</div>
          <div className={style.rawText}>{content.pageDescription}</div>
          <div className={style.shortText}>{content.pageTimeToAnswerText}</div>
          <button onClick={navigation.next} className={style.button}>
            Starta formuläret
            <img src={arrow} alt="Arrow" className={style.buttonIcon} />
          </button>
        </div>
      </div>
    );
  }
}

export default Header;
