import React from "react";
import styles from "./NavigationMenu.module.css";
import Row from "../../Atoms/Row/Row";
import Column from "../../Atoms/Column/Column";

const NavigationMenu = ({ children }) => {
  return (
    <Row>
      <Column>
        <div className={styles.navigationMenu}>{children}</div>
      </Column>
    </Row>
  );
};

export default NavigationMenu;
